import { createStore } from 'vuex';
const context = require.context('./', true, /index.js/);
const modules = {};
context.keys().forEach(file => {
  const fileIndex = file.replace(/(\.\/|\/index\.js$)/g, '');
  modules[fileIndex] = context(file).default;
});

export default createStore({
  modules
});
