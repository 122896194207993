import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';

const locale = document.querySelector('html').getAttribute('lang') || 'es';
export const i18n = () =>
  import(/* webpackChunkName: "locale-[request]" */ `../locales/${locale}.json`).then(msgs => {
    // Here you can initialize other modules with your language, example: moment.js
    return setupI18n({
      globalInjection: true,
      legacy: false,
      locale,
      fallbackLocale: locale,
      messages: { [locale]: msgs.default }
    });
  });

export const SUPPORT_LOCALES = ['en', 'es'];

export const setupI18n = (options = { locale: 'en' }) => {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale);
  return i18n;
};

export const setI18nLanguage = (i18n, locale) => {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html').setAttribute('lang', locale);
};

export const loadLocaleMessages = async (i18n, locale) => {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `../locales/${locale}.json`
  );

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default);

  return nextTick();
};
