import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = () => ({
  counter: 2
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
